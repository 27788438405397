<!--电能设置--基本设置---->
<template>
    <div id="energyActuaUnlnputData" ref="main" v-loading="loading" :element-loading-text="$t('alarm.a0')">
        <!-- 页面标题 -->
        <div class="header-title">
            <span>{{ $t("energy.enedata086") }}</span>
        </div>

        <div class="header">
            <div class="header-left">           
                <div class="header-condition">
                    <span>{{ $t("energy.enedata005") }}</span>
                    <a-select v-model="year" style="width: 120px" @change="yearChange">
                        <a-select-option
                            v-for="(itme, index) in yearList"
                            :key="index"
                            :value="itme"
                            >
                            {{ itme }}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="header-condition">
                    <span>&nbsp;--&nbsp;</span>
                    <a-checkbox v-model="displayInput" @change="displayInputChange" :disabled="disable"> {{$t("energy.enedata091")}} </a-checkbox>
                </div>
                <div class="header-condition">
                    <span>{{ $t("energy.enedata075") }}</span>
                    <a-radio-group v-model="kind" button-style="solid" @change="kindChanges">
                        <a-radio-button value="1">{{ $t("energy.enedata076") }}</a-radio-button>
                        <a-radio-button value="2">{{ $t("energy.enedata077") }}</a-radio-button>
                        <a-radio-button value="3">{{ $t("energy.enedata078") }}</a-radio-button>
                    </a-radio-group>
                </div>
            </div>
            <div>
              
                <a-button  type="primary"   @click="identifyObjects()" :disabled="kind != '1'" > {{$t("energy.enedata090")}}</a-button>
                <a-button  type="primary"   @click="search()" :disabled="disable"> {{ $t("energy.enedata006") }} </a-button>
                <!-- <a-button  type="primary"   @click="csvout()" :disabled="disable"> {{ $t("energy.enedata042") }} </a-button> -->
            </div>
        </div>

        <a-table :style="tableStyle"
            :columns="columns"
            :data-source="data"
            :pagination="pagination"
            :scroll="xscroll"
            size="small"
        >
          <template  v-for="col in slots"
            :slot="col"
            slot-scope="text, record" >
              <div :key="col" >
                  <span style="color:red;" v-if="vailrecord(record,col)">
                    {{text}}
                  </span>
                  <span v-else>
                    {{text}}
                  </span>
              </div>
          </template>
        </a-table>
        <div style="text-align:right;">
            <a-button  type="primary"   @click="save()" :disabled="kind !='1' && (data ==null || data.length<=0)"> {{ $t("energy.enedata023") }} </a-button>
        </div> 
        <a-modal v-model="visible" title="diotitle" @ok="handleOk">
            <div v-loading="dioloading" :element-loading-text="$t('alarm.a0')">
              <a-checkbox  v-model="ene.chk"  :disabled="ene.disable"> {{ene.name}}</a-checkbox>
              <a-checkbox  v-model="ope.chk"  :disabled="ope.disable"> {{ope.name}}</a-checkbox>
              <a-checkbox  v-model="ten.chk" :disabled="ten.disable">  {{ten.name}}</a-checkbox>
            </div>
        </a-modal>  
      
   
    </div>
</template>



<script>
import { getEnergyActualUnInputData,saveEnergyActualUnInputData,downloadEnergyCSVDataWithURL } from "../../../api/energy";
import { downloadUrl } from "../../../utils/utils";
import moment from "moment";


export default {
  name: "energyActuaUnlnputData",
  data() {
    
    return {
      loading: false,
      yearList:[],
      year:undefined,
      kind:'3',
      data:null,
      columns:null,
      pagination:false,
      xscroll: { x: 700 ,y:300},
      displayInput:false,

      disable:false,
      cfdisable:false,

      visible:false,
      options:[],
      chk:[],
      indeterminate: true,
      checkAll: false,
      tableStyle:{width: '100%', height: '300px',padding:'10px'},
      chk1:false,

      ene:{chk:false,name: this.$t("energy.enedata087"),disable:false},
      ope:{chk:false,name: this.$t("energy.enedata088"),disable:false},
      ten:{chk:false,name: this.$t("energy.enedata089"),disable:false},
      dioloading:false,
      diotitle:this.$t("energy.enedata090"),
      slots:[],
      chkDisableds:[],
    };
  },
  computed: {
    
  },
  activated(){
    console.log("activated",this.$store.getters.clientId);
    if(this.$store.getters.clientId){
        console.log("activated load data");
        this.columns = [];
        this.data = [];
        this.initPage(0);
    }
  },
  mounted() { 
    // this.options = [
    //     { value: '1', label:  this.$t("energy.enedata087") },
    //     { value: '2', label:  this.$t("energy.enedata088") },
    //     { value: '3', label:  this.$t("energy.enedata089") },
    //   ];
    // this.initPage(0);
    //     //实现自适应部分
    this.tableSize();
    window.addEventListener("resize", () => this.tableSize());
  },
  beforeDestroy() {
   
  },
  methods:{
    vailrecord(record,col){
        if(record[col]==='-'){
          return false;
        }
        let nt = this.$t('energy.enedata094');
        if(record[col]===nt){
          return false;
        }
        return true;
    },
    optChange(e){ 
      console.log(e);
      console.log(`checked = ${e.target.checked}`);
      //e.target.checked = 
    },
    kindChanges(){
      this.columns = [];
      this.data = [];
    },
    onChange(){

    },
    onCheckAllChange(){

    },
    identifyObjects(){
        let params =  {
            action:2,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            year:this.year
        };

        if(this.kind==='1'){
            params.clientId=this.$store.getters.clientId;
            params.siteId=0;
            params.sitegrId=0;
        }else if(this.kind==='2'){
            params.clientId=this.$store.getters.clientId;
            params.sitegrId=this.$store.getters.sitegrId;
            params.siteId=0;
        }
        console.log("getEnergyActualUnInputData Params",params);
        this.loading = true;
        getEnergyActualUnInputData(params)
        .then((res) => {
            console.log("getEnergyActualUnInputData Result",res);
            if(res.errorCode == '00'){
                if(res.data.err == 1){
                    this.$message.error(this.$t('energy.enedata092'));
                }else if(res.data.err == -2){
                    this.$message.error(this.$t('energy.enedata066'));
                }else{
                  this.visible = true;
                  this.chk = [];
                  //this.options = res.data.noInputOptionDatas
                  this.ene = res.data.noInputOptionDatas[0];
                  this.ope = res.data.noInputOptionDatas[1];
                  this.ten = res.data.noInputOptionDatas[2];
                    // this.columns = res.data.columnList;
                    // this.data    = res.data.list;
                    // this.tableSize();
                }
            }
           
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        })
        .finally(()=>{
            this.loading = false;
        });

      
    },
    handleOk(){
        let params =  {
            action:3,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            year:this.year,
            ene:this.ene.chk,
            ope:this.ope.chk,
            ten:this.ten.chk,
        };

        if(this.kind==='1'){
            params.clientId=this.$store.getters.clientId;
            params.siteId=0;
            params.sitegrId=0;
        }else if(this.kind==='2'){
            params.clientId=this.$store.getters.clientId;
            params.sitegrId=this.$store.getters.sitegrId;
            params.siteId=0;
        }
        console.log("getEnergyActualUnInputData Params",params);
        this.dioloading = true;
        getEnergyActualUnInputData(params)
        .then((res) => {
            console.log("getEnergyActualUnInputData Result",res);
            if(res.errorCode == '00'){
                this.visible = false;
                this.$message.info(this.$t('energy.enedata081'));
                // if(res.data.err == 1){
                //     this.$message.error(this.$t('energy.enedata092'));
                // }else if(res.data.err == -2){
                //     this.$message.error(this.$t('energy.enedata066'));
                // }else{
                //     this.columns = res.data.columnList;
                //     this.data    = res.data.list;
                //     this.tableSize();
                // }
            }else{
              this.$message.info(this.$t('energy.enedata082'));
            }
           
        })
        .catch((err) => {
            this.dioloading = false;
            console.log(err);
        })
        .finally(()=>{
            this.dioloading = false;
        });
    },
    displayInputChange(){

    },
    yearChange(e){
      this.initPage(e);
      this.columns = [];
      this.data = [];
    },
    fncUninputCheck(){
        let nt = this.$t('energy.enedata094');
        for (let i = 0; i < this.data.length; i++) {
            const element = this.data[i];
            for (let j = 0; j < this.slots.length; j++) {
                if(element[this.slots[j]] == '-')
                    continue;
                if(element[this.slots[j]] != nt){
                    this.$message.error(this.$t('energy.enedata095'));
                    return false;
                }
            }
        }
        return true;
    },
    save(){
        if(!this.fncUninputCheck()){
          return;
        }

        let cfg = new Array();
        for (let i = 0; i < this.chkDisableds.length; i++) {
            if(!this.chkDisableds[i]){
              cfg.push(true);
            }
        }

        let params =  {
            action:2,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            reportType:this.rpt,
            year:this.year,
            chk:cfg,
        };

        if(this.kind==='1'){
            params.clientId=this.$store.getters.clientId;
            params.siteId=0;
            params.sitegrId=0;
        }else if(this.kind==='2'){
            params.clientId=this.$store.getters.clientId;
            params.sitegrId=this.$store.getters.sitegrId;
            params.siteId=0;
        }
        console.log("saveEnergyActualUnInputData Params",params);
        this.loading = true;
        saveEnergyActualUnInputData(params)
        .then((res) => {
           console.log("saveEnergyActualUnInputData Result",res);
            if(res.errorCode == '00'){
              this.$message.info(this.$t('energy.enedata081'));
              this.search();
            }else{
              this.$message.info(this.$t('energy.enedata082'));
            }
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        })
        .finally(()=>{
            this.loading = false;
        });
    },
    search(){
        let params =  {
            action:1,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            minyuFlag:this.displayInput,
            year:this.year
        };

        if(this.kind==='1'){
            params.clientId=this.$store.getters.clientId;
            params.siteId=0;
            params.sitegrId=0;
        }else if(this.kind==='2'){
            params.clientId=this.$store.getters.clientId;
            params.sitegrId=this.$store.getters.sitegrId;
            params.siteId=0;
        }
        console.log("getEnergyActualUnInputData Params",params);
        this.loading = true;
        getEnergyActualUnInputData(params)
        .then((res) => {
            console.log("getEnergyActualUnInputData Result",res);
            if(res.errorCode == '00'){
                if(res.data.err == 1){
                    this.$message.error(this.$t('energy.enedata092'));
                }else if(res.data.err == -2){
                    this.$message.error(this.$t('energy.enedata066'));
                }else{
                    this.columns = res.data.columnList;
                    this.data    = res.data.noInputData;
                    this.chkDisableds = res.data.chkDisableds;
                    this.slots = res.data.slots;
                    this.tableSize();
                }
            }
           
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        })
        .finally(()=>{
            this.loading = false;
        });
    },
    initPage(year){
      let params =  {
            action:0,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            year: year,
        };
        console.log("InitPage Params",params);
        this.loading = true;
        getEnergyActualUnInputData(params)
        .then((res) => {
            console.log("LoadData Result",res);
            if(res.errorCode == '00'){

                this.yearList = res.data.yearList;
                if(year ==0){
                  this.year = res.data.fiscalYear;
                }
               
            } 
            this.loading = false;
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
    },
    tableSize() {
        setTimeout(() => {
            let height = this.$refs.main.clientHeight;
            let tableHeight = height - 220;
            if(this.kind === '1'){
              this.xscroll.y = tableHeight-20;
            }else{
               this.xscroll.y = tableHeight;
            }
            this.tableStyle.height = (tableHeight +40 )+ 'px';
        }, 0);
    },
    
  },
  destroyed(){
      window.removeEventListener("resize", () => this.tableSize());
  },
  components: {
    
  },
};
</script>

<style scoped>

#energyActuaUnlnputData {
  overflow: auto;
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'
}
.header-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
}
.header-title::before {
  content: "";
  display: inline-block;
  width:  4px;
  height: 30px;
  margin-right: 20px;
  background-color: #7682ce;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
}
.header-left {
  display: flex;
  justify-content: flex-start;
}
.header-condition {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}
.main{
    width: 100%;
    height:calc(100%-200px);
}
.footer {
  display: flex;
  justify-content:space-between;
  height: 52px;
  padding: 10px 10px;
  box-shadow: 0px 0px 10px #bdbcbc;
}

button {
   margin-left: 15px;
}

</style>


